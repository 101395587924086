export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.client) {
    if (from.meta.scrollPosition && window) {
      // @ts-ignore
      from.meta.scrollPosition.x = window.scrollX;
      // @ts-ignore
      from.meta.scrollPosition.y = window.scrollY;
    }
  }
});
